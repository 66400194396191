import * as React from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import { ExclamationTriangleFill, GearFill } from "react-bootstrap-icons";
import { Stack , Button, Form, FloatingLabel, Alert, InputGroup , Row, Col } from 'react-bootstrap';
import PasswordStrengthMeter from "../components/PasswordStrength";
import Logo from '../assets/shieldhub-logo.png';
import { ArrowRightShort } from 'react-bootstrap-icons';
import { useAuth } from "../components/Auth";
import { useCookies } from "react-cookie";
import api from "../io/user";
import { useBranding } from "../components/Branding";

function Register(){

	let ua = navigator.userAgent.toLowerCase();
	let platformName = ua.match(/ip(?:ad|od|hone)/) ? 'ios' : (ua.match(/(?:webos|android)/) || ['other'])[0];
	let isAndroid = /android/.test(platformName);

	const [ errorbanner, setErrorbanner ] = React.useState(false);
	const [ errormessage , setErrormessage ] = React.useState(false);
	const [ passwordMeterValue , setPasswordMeterValue ] = React.useState(false);
	const [ errorfields , setErrorfields ] = React.useState({firstname:false,lastname:false,emailaddress:false,password:false,confirmpassword:false,mobile:false});
	const [ submitwait , setSubmitwait ] = React.useState(false);
	const formRef = React.createRef(null);
	const auth = useAuth();
	const navigate = useNavigate();
	const branding = useBranding();
	const [ cookies ] = useCookies(['sh_linkid']);
	const [ formattedphonenumber , setFormattedphonenumber ] = React.useState('');

	const gearspin = {
		animation:"spin 2s linear infinite"
	}

	function handlePasswordKeyEvent( event ){

		var password = event.currentTarget.value;

		setPasswordMeterValue(password);

	}

	async function checkEmail(){

		let fields = { ...errorfields };

		fields.emailaddress = false;

		let formData = new FormData(formRef.current);
		let emailaddress = formData.get('emailaddress');

		if(!emailaddress.match(/.+@.+\..+/))
		{
			return;
		}

		const apiresponse = await api.duplicateaccountcheck(emailaddress);

		if(apiresponse.success === true && apiresponse.accountexists === true)
		{
			fields.emailaddress = true;
			setErrorbanner(true);
			setErrormessage("Error: The provided email address has already been registered");
		}

		setErrorfields(fields);

	}

	function formatPhoneNumber(event){

		let autofill_css_selector = false;
		try {

			document.getElementsByTagName('body')[0].matches(':autofill');
			autofill_css_selector = true;
		}
		catch(e)
		{
			autofill_css_selector = false;
		}

		//only handle onkeydown for normal events
		//but onchange with autofill handle specially
		// handle special scenarios with info from 
		// https://github.com/matteobad/detect-autofill

		if(autofill_css_selector === true && event['_reactName'] === 'onChange' && ( event.target.matches(':autofill') || event.target.matches(':-webkit-autofill') ) )
		{
			let autofillphonevalue = event.target.value;

			autofillphonevalue = autofillphonevalue.replace(/[^0-9]/g,'');

			autofillphonevalue = autofillphonevalue.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/,'$1-$2-$3');

			setFormattedphonenumber(autofillphonevalue);

			event.stopPropagation();
			event.preventDefault();
			return;
		}
		else if(autofill_css_selector === true && event['_reactName'] === 'onInput' && event.nativeEvent.inputType === 'insertReplacementText' && ( event.target.matches(':autofill') || event.target.matches(':-webkit-autofill') ) )
		{
			let autofillphonevalue = event.target.value;

			autofillphonevalue = autofillphonevalue.replace(/[^0-9]/g,'');

			autofillphonevalue = autofillphonevalue.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/,'$1-$2-$3');

			setFormattedphonenumber(autofillphonevalue);

			event.stopPropagation();
			event.preventDefault();
			return;
		}
		else if(event['_reactName'] === 'onChange' && event.nativeEvent.inputType === undefined && event.keyCode === undefined)
		{
			let autofillphonevalue = event.target.value;

			autofillphonevalue = autofillphonevalue.replace(/[^0-9]/g,'');

			autofillphonevalue = autofillphonevalue.replace(/([0-9]{3})([0-9]{3})([0-9]{4})/,'$1-$2-$3');

			setFormattedphonenumber(autofillphonevalue);

			event.stopPropagation();
			event.preventDefault();
			return;			
		}
		else if(['onChange','onInput'].indexOf(event['_reactName']) !== -1 && event.nativeEvent.inputType === 'insertText' && !event.target.value.match(/[0-9-]/))
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}
		else if(['onChange','onInput'].indexOf(event['_reactName']) !== -1 && isAndroid && (event.key === 'Unidentified' || event.key === undefined) && event.nativeEvent.inputType === 'insertText' && event.target.value.match(/[0-9-]/))
		{
			//Android Handling :-/

			let autofillphonevalue = event.target.value;

			autofillphonevalue = autofillphonevalue.replace(/[^0-9-]/g,'');

			autofillphonevalue = autofillphonevalue.substring(0,12);

			if(autofillphonevalue.match(/^[0-9]{3}$/))
			{
				autofillphonevalue += '-';
			}
			else if(autofillphonevalue.match(/^[0-9]{3}-[0-9]{3}$/))
			{
				autofillphonevalue += "-";
			}
			else if(autofillphonevalue.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/))
			{
				
			}
			else if(autofillphonevalue.match(/^[0-9]{1,3}-?[0-9]{0,3}-?[0-9]{0,4}$/))
			{
				
			}
			else
			{
				event.stopPropagation();
				event.preventDefault();
				return;
			}
	
			setFormattedphonenumber(autofillphonevalue);

			event.stopPropagation();
			event.preventDefault();
			return;
		}
		else if(['onChange','onInput'].indexOf(event['_reactName']) !== -1 && isAndroid && (event.key === 'Unidentified' || event.key === undefined) && event.nativeEvent.inputType === 'deleteContentBackward')
		{
			//Android Handling :-/

			let newvalue = formattedphonenumber;

			newvalue = newvalue.substring(0,newvalue.length-1);

			setFormattedphonenumber(newvalue);
			event.stopPropagation();
			event.preventDefault();
			return;			
		}
		else if(event['_reactName'] !== 'onKeyDown')
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		let validControlKeyCodes = [8,9,37,39,46];

		var newvalue;

		if(event.keyCode === undefined)
		{
			return;
		}
		else if(validControlKeyCodes.indexOf(event.keyCode) !== -1)
		{
			if(event.keyCode === 8 && formattedphonenumber !== undefined && formattedphonenumber !== null)
			{

				newvalue = formattedphonenumber;

				newvalue = newvalue.substring(0,newvalue.length-1);

				setFormattedphonenumber(newvalue);
			}

			//control keys like Tab, backspace or arrows
			return;
		}
		else if(event.key !== undefined && event.key !== 'Unidentified' && !event.key.match(/[0-9]/))
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		newvalue = formattedphonenumber;
		newvalue += event.key;

		if(newvalue.match(/^[0-9]{3}$/))
		{
			newvalue += "-";
		}
		else if(newvalue.match(/^[0-9]{3}-[0-9]{3}$/))
		{
			newvalue += "-";
		}
		else if(newvalue.match(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/))
		{
			
		}
		else if(newvalue.match(/^[0-9]{1,3}-?[0-9]{0,3}-?[0-9]{0,4}$/))
		{
			
		}
		else
		{
			event.stopPropagation();
			event.preventDefault();
			return;
		}

		setFormattedphonenumber(newvalue);
	}


	async function handleFieldChange( event , field ){

		let fields = { ...errorfields };

		if(event?.currentTarget?.value?.length > 0)
		{
			fields[field] = false;
		}

		let formData = new FormData(formRef.current);

		let password = formData.get('password');
		let confirmpassword = formData.get('confirmpassword');

		if(field === 'password' && password !== confirmpassword)
		{
			fields.password = true;
			fields.confirmpassword = true;
		}
		else
		{
			fields.password = false;
			fields.confirmpassword = false;
		}
		setErrorfields(fields);
	}

	async function handleCreateAccount(event)
	{
		event.preventDefault();

		let errorbanner = false;

		let fields = { ...errorfields };		

		let formData = new FormData(formRef.current);
		let firstname = formData.get('firstname');
		let lastname = formData.get('lastname');
		let businessname = formData.get('businessname');
		let emailaddress = formData.get('emailaddress');
		let password = formData.get('password');
		let confirmpassword = formData.get('confirmpassword');
		let mobile = formData.get('mobile');

		if(password !== confirmpassword)
		{
			fields.password = true;
			fields.confirmpassword = true;
			errorbanner = true;
		}

		if( firstname === null || firstname === undefined || firstname.length === 0 )
		{
			fields.firstname = true;
			errorbanner = true;
		}
		if( lastname === null || lastname === undefined || lastname.length === 0 )
		{
			fields.lastname = true;
			errorbanner = true;
		}
		if( emailaddress === null || emailaddress === undefined || emailaddress.length === 0 )
		{
			fields.emailaddress = true;
			errorbanner = true;
		}
		if( emailaddress !== null && emailaddress !== undefined && emailaddress.length > 0 && !emailaddress.match(/[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,24}/) )
		{
			fields.emailaddress = true;
			errorbanner = true;
		}
		if( mobile === null || mobile === undefined || mobile.length === 0 )
		{
			fields.mobile = true;
			errorbanner = true;
		}
		if( password === null || password === undefined || password.length === 0 )
		{
			fields.password = true;
			errorbanner = true;
		}
		if( confirmpassword === null || confirmpassword === undefined || confirmpassword.length === 0 )
		{
			fields.confirmpassword = true;
			errorbanner = true;
		}

		setErrorbanner(errorbanner);
		setErrorfields(fields);

		if(errorbanner === false)
		{
			setSubmitwait(true);
			let userobject = {
				firstname,
				lastname,
				businessname,
				emailaddress,
				mobile,
				password
			}

			let registerresponse = await api.register(userobject);

			if(registerresponse.success === false)
			{
				setErrormessage(" Error: There was a problem with registration. ");
				return;
			}

			auth.registersession( userobject , registerresponse.sessionid );
			
			navigate("/register/terms");
		}
		else
		{
			setErrormessage(" Error: Please fill in the highlighted required fields. ");
		}

	}

	/* eslint-disable react-hooks/exhaustive-deps */
	React.useEffect(() => {

		if(window.clarity)
		{
			window.clarity('set','userid',auth?.user?.userid);
		}

	},[]);
	/* eslint-enablereact-hooks/exhaustive-deps */	

	return (
		<>
			<Helmet>
				<title>ShieldHub Registration</title>
			</Helmet>
			<div className="login-position container">
				<Stack gap={2}>
					<Row className="justify-content-center login-portal">
						<Col xs="auto" style={{height:'90px'}}>
							{process.env.REACT_APP_LOCALDEBUG ? <img src={Logo} alt="ShieldHub Logo" className="branding"/> : <img src={branding.Company.branding_logo_path} alt="ShieldHub Logo" className="branding"/>}
						</Col>
					</Row> 
					<Row className="justify-content-center login-portal">
						<Col xs={12} sm={10} md={8} lg={6} className="login-container">
							<Form onSubmit={handleCreateAccount} autoComplete="off" ref={formRef}>
								<h2>Join ShieldHub</h2>
								<h6 className="mb-3">Register for a <b><u>free</u></b> account to verify ShieldIDs and purchase your background check.</h6>
								<Row>
									<Col xs={{span:12}} sm={{span:6}} className="p-1">
										<FloatingLabel controlId="firstname" label="FIRST NAME">
											<Form.Control 
												type="text" 
												placeholder="FIRST NAME" 
												name="firstname" 
												isInvalid={errorfields.firstname}
												onChange={(event) => {
													handleFieldChange(event,"firstname")
												}}
											/>
										</FloatingLabel>
									</Col>
									<Col xs={{span:12}} sm={{span:6}} className="p-1">
										<FloatingLabel controlId="lastname" label="LAST NAME">
											<Form.Control 
												type="text" 
												placeholder="LAST NAME" 
												name="lastname" 
												isInvalid={errorfields.lastname}
												onChange={(event) => {
													handleFieldChange(event,"lastname")
												}}
											/>
										</FloatingLabel>					
									</Col>
								</Row>
								<Row>
									<Col xs={{span:12}} className="p-1">
										<FloatingLabel controlId="businessname" label="BUSINESS NAME (optional)">
											<Form.Control 
												type="text" 
												placeholder="BUSINESS NAME (optional)" 
												name="businessname" 
												isInvalid={errorfields.businessname}
												onChange={(event) => {
													handleFieldChange(event,"businessname")
												}}
											/>
										</FloatingLabel>					
									</Col>
								</Row>
								<Row>
									<Col xs={{span:12}} sm={{span:6}} className="p-1">
										<FloatingLabel controlId="emailaddress" label="EMAIL ADDRESS">
											<Form.Control
												name="emailaddress"
												type="emailaddress"
												placeholder="EMAIL ADDRESS"
												aria-label="Email Address"
												aria-describedby="basic-addon1"
												isInvalid={errorfields.emailaddress}
												plaintext={ cookies.sh_linkid !== undefined && cookies.sh_linkid_emailaddress !== undefined ? true : false }
												readOnly={ cookies.sh_linkid !== undefined && cookies.sh_linkid_emailaddress !== undefined ? true : false }
												value={ cookies.sh_linkid !== undefined && cookies.sh_linkid_emailaddress !== undefined ? cookies.sh_linkid_emailaddress : undefined }
												onChange={checkEmail}
											/>
										</FloatingLabel>
									</Col>
									<Col  xs={{span:12}} sm={{span:6}} className="p-1">
										<InputGroup>
											<FloatingLabel controlId="mobile" label="MOBILE PHONE NUMBER">
												<Form.Control
													type="text"
													placeholder="MOBILE PHONE NUMBER"
													name="mobile"
													value={formattedphonenumber}
													isInvalid={errorfields.mobile}
													onKeyDown={formatPhoneNumber}
													onChange={formatPhoneNumber}
													onInput={formatPhoneNumber}
												/>
											</FloatingLabel>
										</InputGroup>
									</Col>
								</Row>
								<Row>
									<Col xs={{span:12}} sm={{span:6}} className="p-1">
										<FloatingLabel controlId="password" label="PASSWORD">
											<Form.Control 
												type="password" 
												placeholder="PASSWORD" 
												name="password" 
												isInvalid={errorfields.password}
												onKeyUp={handlePasswordKeyEvent}
												onChange={(event) => {
													handleFieldChange(event,"password")
												}}
											/>
										</FloatingLabel>
										<PasswordStrengthMeter password={passwordMeterValue}/>
									</Col>
									<Col  xs={{span:12}} sm={{span:6}} className="p-1">
										<FloatingLabel controlId="confirmpassword" label="CONFIRM PASSWORD">
											<Form.Control
												type="password"
												placeholder="CONFIRM PASSWORD"
												name="confirmpassword"
												isInvalid={errorfields.confirmpassword}
												onChange={(event) => {
													handleFieldChange(event,"confirmpassword")
												}}
											/>
										</FloatingLabel>
									</Col>
								</Row>
								<Alert variant="danger" show={errorbanner}> <ExclamationTriangleFill size={20} />{errormessage}</Alert>
								<div style={{float:"left",marginTop:"20px"}}>
									<Button className="cancel-button" variant="link" style={{color:branding.PrimaryColor}}>Cancel</Button>
								</div>
								<div style={{float:"right",marginTop:"20px"}}>
									<Button type="submit" className="continue-button" disabled={submitwait}>Continue { !(submitwait) && <ArrowRightShort size={16} style={{marginTop:"-2px"}} /> }  { submitwait && <GearFill style={gearspin} /> }</Button>
								</div>
							</Form>
						</Col>
					</Row>

					<Row className="justify-content-center login-portal">
						<Col xs={12} sm={10} md={8} lg={6} className="login-container register-container">
							<h2>Already Have an Account?</h2>
							<h6 className="mb-3">Return to the login page to sign-on with your ShieldHub account. </h6>
							<Row className="p-2">
								<Col xs={{span: 10, offset: 1 }}>
									<a className="register-button" href="/login">Return to Login</a>	
								</Col>
							</Row>
						</Col>
					</Row>
				</Stack>
			</div>
		</>
	)

}

export default Register;