import * as React from "react";
import { useNavigate , useLocation } from "react-router-dom";
import { Stack , Button, Form, FloatingLabel, Alert , Row , Col } from 'react-bootstrap';
import { ExclamationTriangleFill } from "react-bootstrap-icons";
import { useAuth } from "../components/Auth";
import { useCookies } from "react-cookie";
import Logo from '../assets/shieldhub-logo.png';
import { Helmet } from "react-helmet-async";
import { useBranding } from "../components/Branding";


function Login(){

	const [ errorbanner, setErrorbanner ] = React.useState(false);
	const [ errormessage , setErrormessage ] = React.useState(false);
	const [ errorfields , setErrorfields ] = React.useState({emailaddress:false,password:false});
	const navigate = useNavigate();
	const auth = useAuth();
	const branding = useBranding();
	const [ cookies ] = useCookies(['sh_linkid','sh_linkid_emailaddress','sh_link_redirect_after_login']);
	const { search } = useLocation();
	const querystring = new URLSearchParams(search, [search]);

	const newpasswordalert = (querystring.get('newpassword') !== null && querystring.get('newpassword') !== undefined);

	function handleFieldChange( event , field ){

		let fields = { ...errorfields };

		if(event?.currentTarget?.value && event.currentTarget.value.length > 0)
		{
			fields[field] = false;
		}

		setErrorfields(fields);
	}

	async function handleLogin(event) {

		event.preventDefault();

		let errorbanner = false;

		let formData = new FormData(event.currentTarget);

		let emailaddress = formData.get('emailaddress');
		let password = formData.get('password');

		let fields = { ...errorfields};

		if(emailaddress === null || emailaddress === undefined || emailaddress?.length === 0)
		{
			fields.emailaddress = true;
			errorbanner = true;
		}
		else
		{
			fields.emailaddress = false;
		}
		if(password === null || password === undefined || password?.length === 0)
		{
			fields.password = true;
			errorbanner = true;
		}
		else
		{
			fields.password = false;
		}

		setErrorfields(fields);

		if(errorbanner === false)
		{
			// both fields are filled out
			// try to send form request

			let authresponse = await auth.login(emailaddress,password);

			if(authresponse.success === false)
			{
				setErrorbanner(true);
				setErrormessage(" Error: The provided username or password are invalid. ");
				return;
			}

			if(authresponse.redirect_to_branded_domain && authresponse.redirect_to_branded_domain.length > 2)
			{
				if(authresponse.twosteprequired === true)
				{
					window.location = `https://${authresponse.redirect_to_branded_domain}/verify`;
					return;
				}
				else
				{
					if(authresponse.role === "ADMIN")
					{
						window.location = `https://${authresponse.redirect_to_branded_domain}/admin`;
						return;
					}
					else if(cookies?.sh_link_redirect_after_login && cookies?.sh_link_redirect_after_login.length > 3)
					{
						window.location = `https://${authresponse.redirect_to_branded_domain}/link/${cookies.sh_link_redirect_after_login}`;
						return;
					}
					else
					{
						window.location = `https://${authresponse.redirect_to_branded_domain}/user`;
						return;
					}
				}
			}

			if(authresponse.twosteprequired === true)
			{
				navigate("/verify", { replace: true });
			}
			else
			{
				if(authresponse.role === "ADMIN")
				{
					navigate("/admin", { replace: true });
				}
				else
				{
					navigate("/user", { replace: true });
				}
			}

		}
		else
		{

			setErrormessage(" Error: Username and Password are required ");

		}

		setErrorbanner(errorbanner);
	}

	return (
		<>
			<Helmet>
				<title>ShieldHub Login</title>
			</Helmet>
			<div className="login-position container">
				<Stack gap={2}>
					<Row className="justify-content-center login-portal">
						<Col xs="auto" style={{height:'90px'}}>
							{process.env.REACT_APP_LOCALDEBUG ? <img src={Logo} alt="ShieldHub Logo" className="branding"/> : <img src={branding.Company.branding_logo_path} alt="ShieldHub Logo" className="branding"/> }
						</Col>
					</Row>
					<Row className="justify-content-center login-portal">
						<Col xs={12} sm={10} md={8} lg={6} className="login-container">
							<Form onSubmit={handleLogin} autoComplete="off">
								<h2>Login to Your Account</h2>
								<h6>Manage your ShieldHub trusted network.</h6>
								<Row className="p-2">
									<Col xs={{ span: 12, offset: 0 }}>
										<FloatingLabel className="Form-Control" controlId="emailaddress" label="EMAIL ADDRESS"> 
											<Form.Control
												name="emailaddress"
												type="emailaddress"
												placeholder="EMAIL ADDRESS"
												aria-label="Email Address"
												aria-describedby="basic-addon1"
												isInvalid={errorfields.emailaddress}
												plaintext={ cookies.sh_linkid !== undefined && cookies.sh_linkid_emailaddress !== undefined ? true : false }
												readOnly={ cookies.sh_linkid !== undefined && cookies.sh_linkid_emailaddress !== undefined ? true : false }
												value={ cookies.sh_linkid !== undefined && cookies.sh_linkid_emailaddress !== undefined ? cookies.sh_linkid_emailaddress : undefined }
												onChange={(event) => {
													handleFieldChange(event,"emailaddress")
												}}
												data-clarity-unmask='true'
											/>
										</FloatingLabel>
									</Col>
								</Row>
								<Row className="p-2">
									<Col xs={{ span: 12, offset: 0 }}>
										<FloatingLabel controlId="password" label="PASSWORD">
											<Form.Control 
												type="password" 
												placeholder="PASSWORD" 
												name="password" 
												isInvalid={errorfields.password} 
												onChange={(event) => {
													handleFieldChange(event,"password")
												}}
											/>
										</FloatingLabel>
									</Col>
								</Row>
								<Row className="p-2">
									<Col xs={{ span: 6, offset: 6 }}>
										<Button variant="link" href="/forgotpassword" className="float-end" style={{fontSize:"12px",color:"#6C757D"}}>Forgot Password?</Button>
									</Col>
								</Row>
								<Row className="p-2">
									<Col xs={{span: 10, offset: 1 }}>
										<Button type="submit" className="login-button">Sign in</Button>	
									</Col>
								</Row>
								<Alert variant="danger" show={errorbanner}> <ExclamationTriangleFill size={20} />{errormessage}</Alert>
								<Alert variant="success" show={newpasswordalert}> Your password has been successfully changed. Please login with your new password. </Alert>
							</Form>
						</Col>
					</Row>


					<Row className="justify-content-center login-portal">
						<Col xs={12} sm={10} md={8} lg={6} className="login-container register-container">
							<h2>New to ShieldHub?</h2>
							<h6 className="mb-3">Register for a <b><u>free</u></b> account to verify ShieldIDs<br />and purchase your background check.</h6>
							<Row className="p-2">
								<Col xs={{span: 10, offset: 1 }}>
									<a className="register-button" href="/register">Get Started Today</a>	
								</Col>
							</Row>
						</Col>
					</Row> 

				</Stack>
			</div>
		</>
	);
}

export default Login;